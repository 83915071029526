/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../styles/index.css';
import Layout from "../components/Layout";
import '@brainhubeu/react-carousel/lib/style.css';
import {Map, Placemark, YMaps} from "react-yandex-maps";

const mapData = {
    center: [55.670793, 37.445408],
    zoom: 17,
    controls: ['zoomControl', 'fullscreenControl'],
};

const Bakery = ({}) => {
    return (
        <>
            <Layout location={"/contacts"} type={"boulangeries"}>
                <div>
                    <div className="fournil d_0">
                        <div className="double">
                            <div>
                                <YMaps query={{
                                    lang: 'ru_RU',
                                    ns: 'use-load-option',
                                    load:
                                        'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon',
                                }}>
                                    <Map defaultState={mapData} height={"400px"} width={"100%"}
                                         options={{suppressMapOpenBlock: false}}>
                                        <Placemark defaultGeometry={mapData.center}
                                                   properties={{
                                                       iconContent: 'Bongard',
                                                       balloonContentBody:
                                                           '119361, г. Москва, ул. Озерная, д. 42, оф. 609<br/>E-mail: info@bongard.pro<br/>Телефон: +7(495)960-18-19<br/>Технический отдел: +7(965)242-20-61'
                                                   }}
                                                   options={{
                                                       preset: 'islands#redStretchyIcon'
                                                   }}/>
                                    </Map>
                                </YMaps>
                            </div>
                            <div style={{padding: '3%'}}>
                                <h2 className="h1">Официальный дистрибьютор Bongard в России</h2>
                                <table>
                                    <tr>
                                        <td><b>Адрес:</b></td>
                                        <td>119361, г. Москва, ул. Озерная, д. 42, оф. 609</td>
                                    </tr>
                                    <tr>
                                        <td><b>E-mail:</b></td>
                                        <td>info@bongard.pro</td>
                                    </tr>
                                    <tr>
                                        <td><b>Телефоны:</b></td>
                                        <td><a href="tel:+74959601819">+7(495)960-18-19</a></td>
                                    </tr>
                                    <tr>
                                        <td><b>Технический отдел:</b></td>
                                        <td><a href="tel:+79652422061">+7(965)242-20-61</a></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Bakery;
